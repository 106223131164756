<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'">

    <!-- <Breadcrumbs :title="`Add New Questions`" :items="items"/> -->
    <Snackbar ref="snackbar"/>

    <div class="mt-5">
      <div class="title black--text mb-2">My Account</div>
      <div class="mb-4">Account</div>
      <ValidationObserver ref="observer">
        <v-form>
          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
            <v-text-field 
              label="Name"
              autocomplete="off"
              outlined
              dense
              v-model="form.fullname"
              :error-messages="errors"
              color="#F05326">
            </v-text-field>
          </ValidationProvider>
           <ValidationProvider name="Email address" rules="required" v-slot="{ errors }">
            <v-text-field 
              label="Email address"
              autocomplete="off"
              outlined
              dense
              readonly
              v-model="form.email"
              :error-messages="errors"
              color="#F05326">
              <!-- <template v-slot:append>
                <div class="red--text font-weight-bold body-2">Silahkan hubungi customer service untuk mengubah email Anda.</div>
              </template> -->
            </v-text-field>
           </ValidationProvider>

          <div class="mb-4">Security</div>
          <div v-show="password">
            <ValidationProvider name="Current Password" rules="min:4|max:20" v-slot="{ errors }">
              <v-text-field
                class="mt-2 mb-1" 
                autocomplete="off"
                outlined
                dense 
                color="#F05326"
                label="Current Password"
                v-model="form.currentPassword" 
                :type="passwordShow.showOld ? 'text' : 'password'"
                :append-icon="passwordShow.showOld ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow.showOld = !passwordShow.showOld"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>
            <ValidationProvider name="New Password" vid="confirm" rules="min:4|max:20" v-slot="{ errors }">
                <v-text-field
                class="mt-2 mb-1"
                autocomplete="new-password"
                outlined
                dense 
                color="#F05326"
                label="New Password"
                v-model="form.newPassword" 
                :type="passwordShow.showNew ? 'text' : 'password'"
                :append-icon="passwordShow.showNew ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow.showNew = !passwordShow.showNew"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>
            <ValidationProvider name="Confirm New Password" rules="min:4|max:20|confirmed:confirm" v-slot="{ errors }">
              <v-text-field
                v-on:keyup.enter="updatePassword"
                class="mt-2 mb-1" 
                autocomplete="new-password"
                outlined
                dense 
                color="#F05326"
                label="Confirm New Password"
                v-model="form.verifyNewPassword" 
                :type="passwordShow.showConfirm ? 'text' : 'password'"
                :append-icon="passwordShow.showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow.showConfirm = !passwordShow.showConfirm"
                :error-messages="errors"
                required>
              </v-text-field>
            </ValidationProvider>
          </div>
          <div>
            <v-btn
              color="#F05326"
              class="text-capitalize"
              outlined
              dense
              depressed
              @click="password = !password">
              {{ !password ? 'Change Password' : 'Undo Change Password' }}
            </v-btn>
          </div>
          <v-card-actions :class="$vuetify.breakpoint.name === 'xs' ? 'mt-2' : ''">
            <v-spacer></v-spacer>
            <v-btn
              dense
              depressed
              :loading="$store.state.process.run"
              :disabled="$store.state.process.run"
              @click="save"
              class="white--text text-capitalize"
              color="#F05326">
              Save Change
            </v-btn>
          </v-card-actions>
        </v-form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { get, post, put , destroy } from '@/service/Axios'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Snackbar from '@/components/Snackbar.vue'
export default {
  components: { Breadcrumbs, Snackbar },
  data() {
    return {
      password: false,
      passwordShow: {
        showOld: false,
        showNew:false,
        showConfirm:false,
      },
      form: {
        fullname: "",
        email: "",
        currentPassword: "",
        newPassword: "",
        verifyNewPassword: ""
      },
      process:{
        run: false
      }
    }
  },
  computed:{
    user(){
      return this.$store.state.user
    }
  },
  watch:{},
  created(){
    this.reset()
  },
  mounted(){},
  methods:{
    reset(){
      this.form = {
        fullname: this.user.fullname,
        email: this.user.email,
        currentPassword: "",
        newPassword: "",
        verifyNewPassword: ""
      }
    },
    async save(){
      this.$store.state.process.run = true
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        await put(`api/v1/user/update`,{
          data: {
            name: this.form.name,
            email: this.form.email,
            current_password: this.form.currentPassword,
            new_password: this.form.newPassword,
            verify_new_password: this.form.verifyNewPassword,
          }
        }).then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.$store.state.process.run = false
            this.$refs.snackbar.open("#4CAF50", `Data Updated Succesfully`);
          } else {
            this.$store.state.process.run = false
            this.$refs.snackbar.open("error", `Data Updated Failed`);
          }
        })
      }
    },
  }
}
</script>

<style>

</style>